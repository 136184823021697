<template>
  <div class="container-fluid mt-2 mb-5">
    <vs-dialog not-center v-model="delSensor">
      <template #header>
        <h4 class="not-margin">Delete <b>sensor</b></h4>
      </template>
      <div class="con-content">
        <p>
          Are you sure you want to delete the sensor?
        </p>
      </div>
      <template #footer>
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="deleteSensor()" transparent> Yes! </vs-button>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="delSensor = false" danger transparent>
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </template>
    </vs-dialog>



    <div class="row justify-content-center align-items-center">
      <div class="col-md-12 d-flex align-items-center">
        <img src="@/assets/scouts.png" width="33" class="me-2" />
        <h3 class="mb-0">Soil Data</h3>
      </div>
      <div class="col-md-12 mt-2 mb-5">
        <div class="row">
          <div class="col-md-4 mt-3" v-for="(item, index) in farms" :key="index">
            <div class="card main-card h-100">
              <div class="card-body">
                <h5 class="text-main-gradient">{{ item.name }}</h5>
                <!-- <span class="text-muted smallText"><span
                    class="material-icons smallText me-1">folder</span>{{item.storage_path}}</span> <br> -->
                <div class="card card-station mt-1"
                  v-for="(itemStation, indexStation) in item.stations.filter(station => station.sensor_type === 3)"
                  :key="indexStation">
                  <div class="card-body">
                    <!-- <span class="text-main-gradient-secondary">{{itemStation.name}}</span> <br> -->
                    <!-- <span class="material-icons smallText me-1">category</span>
                    <span class="text-muted smallText">{{item.stations[0].sensor_description}} </span> -->
                    <ul class="list-group smallText">
                      <li class="list-group-item" v-for="(item2, index2) in itemStation.sensors" :key="index2"
                        v-if="parseInt(item2.sensor_type) == 3">
                        <template v-if="parseInt(item2.last_status) == 0">
                          <span class="smallText d-flex align-items-center justify-content-left">
                            <div class="circle-gray me-1"></div>
                            {{ item2.name }} config: {{ item2.config }}
                          </span>
                          <!-- <a class="text-link" @click="
                                turnSoil(
                                  item2.id,
                                  1,
                                  item2.name,
                                  item2.config,
                                  item.storage_path
                                )
                              ">Turn on</a> -->
                          <a class="text-link ms-2" @click="last_mesure(item2)">Last measure</a>
                          <!-- <a class="text-link ms-2" @click="delete_soil(item2)">Delete</a> -->
                        </template>
                        <template v-else>
                          <span class="smallText d-flex align-items-center justify-content-left">
                            <div class="circle me-1"></div>
                            {{ item2.name }} config: {{ item2.config }}
                          </span>
                          <!-- <a class="text-link" @click="turnSoil(item2.id, 0, item2.name, item2.config)">Turn off</a> -->
                          <a class="text-link ms-2" @click="last_mesure(item2)">Last measure</a>
                          <!-- <a class="text-link ms-2" @click="delete_soil(item2)">Delete</a> -->
                        </template>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <transition name="fade">
            <div class="col-md-8" v-if="lastMeasure && lastMeasure.length > 0">
              <h3 class="text-main-gradient-secondary">Last measure</h3>
              <p>
                <strong>Sensor:</strong> {{ selectedSensor.name }}<br />
                <strong>Config/Serial number:</strong> {{ selectedSensor.config
                }}<br />
              </p>
              <ul class="list-group smallText" v-if="lastMeasure && lastMeasure.length > 0">
                <li class="list-group-item"><strong class="text-main-gradient-secondary">1.-Observation_time:
                  </strong>{{moment(lastMeasure[0].observation_time).format("MMM Do YYYY, HH:mm:ss")}}
                </li>
                <li class="list-group-item"><strong class="text-main-gradient-secondary">2.-Moisture:
                  </strong>{{lastMeasure[0].moisture}}</li>
                <li class="list-group-item"><strong class="text-main-gradient-secondary">3.-Temperature:
                  </strong>{{lastMeasure[0].temperature}}</li>
                <li class="list-group-item"><strong class="text-main-gradient-secondary">4.-Conductivity:
                  </strong>{{lastMeasure[0].conductivity}}</li>
                <li class="list-group-item"><strong class="text-main-gradient-secondary">5.-Salinity:
                  </strong>{{lastMeasure[0].salinity}}</li>
                <li class="list-group-item"><strong class="text-main-gradient-secondary">6.-Water_balance:
                  </strong>{{lastMeasure[0].water_balance}}</li>
                <li class="list-group-item"><strong class="text-main-gradient-secondary">7.-Dielectricity:
                  </strong>{{lastMeasure[0].dielectricity}}</li>
              </ul>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapActions } from "vuex";
  import axios from "axios";
  import SetInterval from "set-interval";
  // import { ipcRenderer } from "electron";

  var config = require("@/config/api.json");
  const API = config.api;
  export default {
    name: "SoilData",
    props: {
      itemData: Object, // Declara una propiedad llamada itemData
    },
    data() {
      return {
        user: null,
        farms: [],
        active: false,
        options: [],
        lastMeasure: [],
        selectedSensor: [],
        delSensor: false
      };
    },

    mounted() {
      setTimeout(() => {
        const storedUser = localStorage.getItem("user");
        if (storedUser) {
          this.user = JSON.parse(storedUser);
          this.getFarms();
          // this.verifySoil();
        }
      }, 666);
      setTimeout(() => {
        console.log("Soil-scouts", this.itemData.id);
      }, 333);
    },
    methods: {
      async getWeatherDataSoil(serial, path, id, name) {
        await axios
          .get(`${API}/getScoutData/${serial}/${id}`)
          .then((data) => {
            if (data.data == "token_not_valid") {
              this.notify("success", `Scout data taking longer than expected`);
            } else {
              let soilData = data.data[0].last_measurement;

              let us = this.user;
              // ipcRenderer.send("createSoilFile", {
              //   soilData,
              //   us,
              //   path,
              //   id,
              //   serial,
              //   name,
              // });
            }
          })
          .catch((err) => {
            console.log(err);
            this.notify("success", `Error reading Soil: no data received`);
          });
      },

      async last_mesure(item) {
        this.selectedSensor = item;
        this.lastMeasure = [];
        try {
          const response = await axios.get(
            `${API}/soil-last-measure/${this.selectedSensor.id}`
          );
          setTimeout(() => {
            this.lastMeasure = response.data;
          }, 333);
        } catch (error) {
          console.error("Error", error);
          this.notify("error", "Failed to fetch farms");
        }
      },


      async delete_soil(item) {
        this.selectedSensor = item;
        this.lastMeasure = [];
        this.delSensor = true;
        console.log(item)

      },

      deleteSensor() {
        axios
          .post(`${API}/delete-scout-sensor`, {
            sensor_id: this.selectedSensor.id,
            station_id: this.selectedSensor.station_id
          })
          .then((response) => {
            this.notify("success", "Sensor deleted successfully");
          })
          .catch((error) => {
            console.error(error.response.data.error);
            this.notify("error", error.response.data.error);
          })
          .finally(() => {
            this.delSensor = false;
            this.$emit("soil-deleted", true);
          });
      },

      async turnSoil(id, value, name, serial, path) {
        try {
          const response = await axios.post(`${API}/turnSoil`, {
            id: id,
          });

          if (parseInt(value) == 0) {
            SetInterval.clear(`soilScout${id}`);
            this.notify("success", `Stopped ${name}`);
            this.$emit("soil-turned", false);
          } else {
            SetInterval.clear(`soilScout${id}`);
            this.getWeatherDataSoil(serial, path, id, name);
            SetInterval.start(
              () => {
                this.getWeatherDataSoil(serial, path, id, name);
              },
              60000,
              `soilScout${id}`
            );
            this.notify("success", `Started ${name}`);
            this.$emit("soil-turned", true);
          }
          this.getFarms();
        } catch (error) {
          console.error("Error", error);
          this.notify("error", "Failed to fetch farms");
        }
      },

      async getFarms() {
        this.farms = [];
        try {
          const response = await axios.get(
            `${API}/getFarmsByOwnerSingle/${this.user.id}/${this.itemData.id}`
          );
          this.farms = response.data;
          console.log('FARMS FROM SCOUTS::::')
          console.log(this.farms)
        } catch (error) {
          console.error("Error", error);
          this.notify("error", "Failed to fetch farms");
        }
      },
      async verifySoil() {
        if (this.farms && this.farms.length > 0) {
          this.farms.forEach((farm) => {
            if (farm.stations && farm.stations.length > 0) {
              const soilStations = farm.stations.filter(
                (station) => station.sensor_type === 3
              );
              if (soilStations.length > 0) {
                soilStations.forEach((station) => {
                  if (station.sensors && station.sensors.length > 0) {
                    const activeSensors = station.sensors.filter(
                      (sensor) => sensor.last_status === 1
                    );
                    if (activeSensors.length > 0) {
                      activeSensors.forEach((element) => {
                        SetInterval.start(
                          () => {
                            this.getWeatherDataSoil(
                              element.config,
                              farm.storage_path,
                              element.id,
                              station.name
                            );
                          },
                          60000,
                          `soilScout${element.id}`
                        );
                        this.getWeatherDataSoil(
                          element.config,
                          farm.storage_path,
                          element.id,
                          station.name
                        );
                      });
                    }
                  }
                });
              }
            }
          });
        }
      },
    },
  };
</script>