<template>
  <div class="container-fluid mt-2 mb-5">

    <!-- Modal eliminar usuario de fb y de db -->
    <vs-dialog not-center v-model="modalDelete">
      <template #header>
        <h4 class="not-margin">
          Delete <b>user</b>
        </h4>
      </template>
      <div class="con-content">
        <p>
          ¿Are you sure you want to delete user {{selectedUser.email}}?
        </p>
      </div>
      <template #footer>
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="deleteUser()" transparent>
                Yes!
              </vs-button>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="modalDelete=false" danger transparent>
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </template>
    </vs-dialog>

    <!-- Edición de usuario y permisos -->
    <vs-dialog not-center v-model="modalEdit">
      <template #header>
        <h4 class="not-margin">
          Edit <b>user</b>
        </h4>
      </template>
      <div class="con-content">
        <div class="form-group">
          <p class="smallText">
            <strong>Display Name: </strong> {{displayNameEdit}} <br>
            <strong>Email: </strong> {{emailEdit}} <br>
          </p>

          <p class="smallText">Send password recovery email <a @click="sendRecovery()" class="text-link">here</a>

          </p>
          <p class="smallText">
            User level
            <select class="form-control mt-2" id="userLevel" v-model="selectedLevelEdit">
              <option value="manager">Manager</option>
              <option value="technical">Technical</option>
            </select>
          </p>
          <div class="form-group  mt-3" v-if="showSwitch">
            <p class="smallText">App permissions</p>
            <div class="row">
              <div class="col-md-4 mt-2" v-for="permission in permissions" :key="permission.id">
                <vs-switch v-model="selectedPermissionsEdit" :val="permission.id" :label="permission.name">
                  <template #off>
                    {{permission.name}}
                  </template>
                  <template #on>
                    {{permission.name}}
                  </template>
                </vs-switch>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="editUser()" transparent>
                Save Changes
              </vs-button>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="modalEdit=false" danger transparent>
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </template>
    </vs-dialog>

    <div class="row justify-content-start align-items-center">
      <div class="col-md-12">
        <h3>Users</h3>
      </div>
      <div class="col-md-12">
        <div class="card main-card">
          <div class="card-body">
            <h4>Create user</h4>
            <div class="form-group">
              <p class="smallText">Display Name
                <input type="text" class="form-control mt-2" id="displayName" placeholder="John Doe"
                  v-model.trim="displayName">
              </p>
              <p class="smallText">Email
                <input type="text" class="form-control mt-2" id="displayName" placeholder="example@agtl.ink"
                  v-model.trim="email">
              </p>
              <p class="smallText ">Password
                <input type="password" class="form-control mt-2" placeholder="* * * * * *" aria-label="******"
                  v-model="password">
                <input type="password" class="form-control mt-2" placeholder="Confirm Password" aria-label="******"
                  v-model="confirmPassword">
              </p>
              <p class="smallText">User level

                <select class="form-control mt-2" id="userLevel" v-model="selectedLevel">
                  <option value="manager">Manager</option>
                  <option value="technical">Technical</option>
                </select>
              </p>
            </div>
            <div class="form-group  mt-3" v-if="showSwitch">
              <p class="smallText">App permissions</p>
              <div class="row">
                <div class="col-md-4 mt-2" v-for="permission in permissions" :key="permission.id">
                  <vs-switch v-model="selectedPermissions" :val="permission.id" :label="permission.name">
                    <template #off>
                      {{permission.name}}
                    </template>
                    <template #on>
                      {{permission.name}}
                    </template>
                  </vs-switch>
                </div>
              </div>
            </div>
            <div class="form-group  mt-3">
              <!-- <button type="button" class="btn btn-primary mt-3" @click="createUser()"><span class="spanButton">Create
                  user</span></button> -->
              <vs-button @click="createUser()">Create user</vs-button>
            </div>

          </div>
        </div>
        <div class="card main-card mt-5 mb-5">
          <div class="card-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>User</th>
                  <th>Level</th>
                  <th>Created</th>
                  <th>Operations</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(item, index) in users" :key="index">
                  <td>
                    <strong>
                      {{item.displayName}}</strong> <br>
                    <small>{{item.email}}</small>
                  </td>
                  <td>{{item.level}}</td>
                  <td>{{moment().format("MMM Do YYYY")}}</td>
                  <td>
                    <template v-if="item.uid != user">
                      <a class="text-link smallText" @click="editUserModal(item)"><span
                          class="material-icons smallText me-1">edit</span>Edit</a> <br>
                      <a class="text-link smallText" @click="delUserModal(item)" v-if="item.level!='admin'"><span
                          class="material-icons smallText me-1">delete</span>Delete</a>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  var config = require("@/config/api.json");
  const API = config.api;
  import axios from 'axios';
  import { auth } from '../fb';
  import { sendPasswordResetEmail } from "firebase/auth";

  export default {
    name: 'Users',
    data() {
      return {
        selectedLevel: 'manager',
        selectedLevelEdit: 'manager',
        permissions: [],
        selectedPermissions: [],
        selectedPermissionsEdit: [],
        displayName: '',
        email: '',
        password: '',
        confirmPassword: '',
        showSwitch: true,
        users: [],
        modalDelete: false,
        modalEdit: false,
        selectedUser: [],
        displayNameEdit: null,
        emailEdit: null,
        user: null
      };
    },
    watch: {

      // selectedLevel(newLevel) {
      //   if (newLevel === 'manager') {
      //     // Cambiar el estado o la propiedad para mostrar el vs-switch
      //     this.showSwitch = true;
      //   } else {
      //     // Cambiar el estado o la propiedad para ocultar el vs-switch
      //     this.showSwitch = false;
      //   }
      // },
      // selectedLevelEdit(newLevel) {
      //   if (newLevel === 'manager') {
      //     // Cambiar el estado o la propiedad para mostrar el vs-switch
      //     this.showSwitch = true;
      //   } else {
      //     // Cambiar el estado o la propiedad para ocultar el vs-switch
      //     this.showSwitch = false;
      //   }
      // }
    },

    mounted() {
      // Obtener los permisos desde el servidor
      this.getPermissions();
      this.getUsers();
    },
    methods: {


      async editUser() {

        const updUser = {
          id: this.selectedUser.id,
          level: this.selectedLevelEdit,
          permissions: this.selectedPermissionsEdit
        };
        this.openLoading();

        // Realizar la solicitud al servidor para crear el usuario
        await axios.post(`${API}/update-user`, updUser)
          .then(response => {
            this.selectedLevelEdit = 'manager';
            this.selectedPermissionsEdit = [];

          })
          .catch(error => {
            // Manejar el error, mostrar una notificación de error, etc.
            console.log(error)
            this.notify('error', error.response.data.error);
            this.closeLoading();
            return;
          })
          .finally(() => {
            this.modalEdit = false;
            this.closeLoading();
            this.getUsers();
            this.notify('success', 'The user was udpated successfully.');
          });
      },

      sendRecovery() {

        this.openLoading();

        sendPasswordResetEmail(auth, this.selectedUser.email)
          .then(() => {
            this.notify('success', 'Password reset email sent');
          })
          .catch((error) => {
            this.notify('error', `Error: ${error.code}`);
          })
          .finally(() => {
            this.closeLoading();
          });
      },

      editUserModal(item) {
        this.modalEdit = true;
        this.selectedUser = [];
        this.selectedUser = item;
        this.displayNameEdit = this.selectedUser.displayName;
        this.emailEdit = this.selectedUser.email;
        this.selectedLevelEdit = this.selectedUser.level;
        this.selectedPermissionsEdit = this.selectedUser.permissions;
      },

      deleteUser() {
        // Envía la solicitud HTTP DELETE utilizando Axios
        axios
          .delete(`${API}/users/${this.selectedUser.id}/${this.selectedUser.uid}`)
          .then(response => {
            // Aquí puedes manejar la respuesta exitosa, si es necesario
            this.notify('success', 'User deleted successfully');
          })
          .catch(error => {
            // Aquí puedes manejar el error, si es necesario
            console.error(error.response.data.error);
            this.notify('error', error.response.data.error);
          })
          .finally(() => {
            // Cierra el modal de eliminación
            this.getUsers();
            this.modalDelete = false;
          });
      },

      delUserModal(item) {
        this.modalDelete = true;
        this.selectedUser = [];
        this.selectedUser = item;
      },

      async getUsers() {
        this.users = [];
        const storedUser = localStorage.getItem('user');
        if (storedUser) {

          const userObject = JSON.parse(storedUser);
          this.user = userObject.uid;

          await axios.get(`${API}/users/${userObject.parent_uid}`)
            .then(response => {
              this.users = response.data;
            })
            .catch(error => {
              console.error('Error fetching users:', error);
            });
        }
      },
      async getPermissions() {
        await axios.get(`${API}/permissions`)
          .then(response => {
            this.permissions = response.data;
          })
          .catch(error => {
            console.error('Error fetching permissions:', error);
          });
      },
      async createUser() {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
          // Recuperar los datos del usuario y sus permisos del localStorage
          const userObject = JSON.parse(storedUser);

          // Validar los campos requeridos
          if (!this.vEmpty(this.displayName, 'Display Name')) {
            return;
          }

          if (!this.vEmpty(this.email, 'Email')) {
            return;
          }
          if (!this.vEmail(this.email)) {
            this.notify('error', 'Error: Invalid email');
            return;
          }

          if (!this.vEmpty(this.password, 'Password')) {
            return;
          }

          if (!this.vEmpty(this.confirmPassword, 'Confirm Password')) {
            return;
          }

          if (this.password !== this.confirmPassword) {
            this.notify('error', 'Passwords do not match');
            return;
          }

          if (this.selectedLevel == 'manager' && this.selectedPermissions.length == 0) {
            this.notify('error', 'Please select manager permissions');
            return;
          }

          // Crear el objeto de usuario a enviar al servidor
          const newUser = {
            partent_uid: userObject.parent_uid,
            displayName: this.displayName,
            email: this.email,
            password: this.password,
            level: this.selectedLevel,
            permissions: this.selectedPermissions
          };


          this.openLoading();

          // Realizar la solicitud al servidor para crear el usuario
          await axios.post(`${API}/create-users`, newUser)
            .then(response => {
              this.displayName = '';
              this.email = '';
              this.password = '';
              this.generateRandomPassword = false;
              this.selectedPermissions = [];
              this.closeLoading();
              this.getUsers();
              this.notify('success', 'The user was created successfully.');
            })
            .catch(error => {
              // Manejar el error, mostrar una notificación de error, etc.
              console.log(error)
              this.notify('error', error.response.data.error);
              this.closeLoading();
              return;
            });
        }
      },
    }
  };
</script>